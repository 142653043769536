<template>
  <div class="row customFooterModalBodyEmail">
    <div class="col-md-8 pr-0">
      <b-card class="h-100">
        <div class="ss--modal-step large-font ss-primary-font-color u-text-bold u-mb-small d-flex align-items-center"><span class="u-mr-small">1</span>Choose the Job Schedule</div>
        <small class="mb-3 d-block">Something goes here</small>
        <b-tabs content-class="mt-3">
          <b-tab title="Daily" lazy  id="email-modal-schedule-tab-daily-tab">
            <div>
              <span class="c-toolbar__state-title u-text-capitalize d-block">Select Time</span>
              <date-picker
               id="email-modal-schedule-tab-daily-tab-time"
                format="hh:mm a"
                value-type="format"
                v-model="dailyTime"
                placeholder="Select Time"
                type="time"
                :time-picker-options="{
                  start: '6:30',
                  step: '00:30',
                  end: '18:00',
                }"
              ></date-picker>
            </div>
          </b-tab>

          <b-tab title="Weekly" lazy id="email-modal-schedule-tab-weekly-tab">
            <b-form-group class="mb-0 ml-md-auto custom-group-radio-buttons" v-slot="{ariaDescribedby}">
              <div class="mb-3">
                <span class="c-toolbar__state-title u-text-capitalize d-block">Day of the week</span>
                <b-form-checkbox-group
                  id="btn-radios-1 email-modal-schedule-tab-weekly-group-buttons"
                  button-variant="outline-primary"
                  v-model="selectedWeeklyDay"
                  :options="options"
                  :aria-describedby="ariaDescribedby"
                  name="radios-btn-default"
                  buttons
                ></b-form-checkbox-group>
              </div>
            </b-form-group>
            <div>
              <span class="c-toolbar__state-title u-text-capitalize d-block">Select Time</span>
              <date-picker
                id="email-modal-schedule-tab-weekly-time"
                format="hh:mm a"
                value-type="format"
                v-model="weeklyTime"
                placeholder="Select Time"
                type="time"
                :time-picker-options="{
                  start: '6:30',
                  step: '00:30',
                  end: '18:00',
                }"
              ></date-picker>
            </div>
          </b-tab>

          <b-tab title="Monthly" lazy id="email-modal-schedule-tab-monthly-tab">
            <div class="mb-3">
              <span class="c-toolbar__state-title u-text-capitalize d-block">Day Of the month</span>
              <date-picker id="email-modal-schedule-tab-monthly-day-of-month" format="DD-MMM-YYYY" :minute-step="15" type="date" v-model="dayOfMonth" lang="en"></date-picker>
            </div>
            <div>
              <span class="c-toolbar__state-title u-text-capitalize d-block">Select Time</span>
              <date-picker
                id="email-modal-schedule-tab-monthly-time"
                format="hh:mm a"
                value-type="format"
                v-model="monthlyTime"
                placeholder="Select Time"
                type="time"
                :time-picker-options="{
                  start: '6:30',
                  step: '00:30',
                  end: '18:00',
                }"
              ></date-picker>
            </div>
          </b-tab>

          <b-tab title="Interval" lazy id="email-modal-schedule-tab-interval-tab">
            <div>
              <span class="c-toolbar__state-title u-text-capitalize">Every</span>
              <div class="d-flex">
                <input class="form-control col-2 mr-2" type="number" id="email-modal-schedule-tab-interval-every"/>
                <v-select id="email-modal-schedule-tab-interval-when" :options="['Minute', 'Hour', 'Day']" label="Select" class="px-0 col-3" placeholder="select"></v-select>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div class="col-md-4">
      <b-card class="h-100">
        <div class="ss--modal-step large-font ss-primary-font-color u-text-bold u-mb-small d-flex align-items-center"><span class="u-mr-small">2</span>Choose Your Start and End Date</div>
        <small class="mb-3 d-block">Something goes here</small>
        <div class="mb-3">
          <span class="c-toolbar__state-title u-text-capitalize">Start Date</span>
          <date-picker id="email-modal-step-2-start-date" format="DD-MMM-YYYY - HH:mm a" :minute-step="15" style="width: 100%;" type="datetime" v-model="date" lang="en"></date-picker>
        </div>

        <div>
          <span class="c-toolbar__state-title u-text-capitalize">End Date</span>
          <date-picker id="email-modal-step-2-start-date" format="DD-MMM-YYYY - HH:mm a" :minute-step="15" style="width: 100%;" type="datetime" v-model="date" lang="en"></date-picker>
        </div>
      </b-card>
    </div>

    <div class="col-md-12 mt-3">
      <b-card>
        <div class="ss--modal-step large-font ss-primary-font-color u-text-bold u-mb-small d-flex align-items-center"><span class="u-mr-small">3</span>Confirmation Your Job Schedule</div>
        <small class="mb-3 d-block">Something goes here</small>
        <div class="row">
          <div class="col-md-3">
            <span class="c-toolbar__state-title u-text-capitalize d-block">Weekly day</span>
            <strong id="email-modal-step-3-weekly-day">Monday, Thursday</strong>
          </div>
          <div class="col-md-3">
            <span class="c-toolbar__state-title u-text-capitalize d-block">Weekly Time</span>
            <strong id="email-modal-step-3-weekly-time">10:00 AM</strong>
          </div>
          <div class="col-md-3">
            <span class="c-toolbar__state-title u-text-capitalize d-block">Start Date</span>
            <strong id="email-modal-step-3-start-date">May 17, 2022</strong>
          </div>
          <div class="col-md-3">
            <span class="c-toolbar__state-title u-text-capitalize d-block">End Date</span>
            <strong id="email-modal-step-3-end-date">May 18, 2022</strong>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
  import DatePicker from "vue2-datepicker";
  //  const CalendarPanel = DatePicker.components.CalendarPanel
  export default {
    components: {
      DatePicker,
    },
    data() {
      return {
        date: new Date(),
        value: new Date(),
        dailyTime: null,
        weeklyTime: null,
        dayOfMonth: null,
        monthlyTime: null,
        options: [
          {text: "Monday", value: "monday"},
          {text: "Tuesday", value: "tuesday"},
          {text: "Wednesday", value: "wednesday"},
          {text: "Thursday", value: "thursday"},
          {text: "Friday", value: "friday"},
          {text: "Saturday", value: "saturday"},
          {text: "Sunday", value: "sunday"},
        ],
      };
    },
    methods: {
      // selectDate (date) {
      //   this.value = date
      // }
    },
  };
</script>
